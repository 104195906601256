// tinyceuticals.com
// script.js  - Utility scripts for landing page.

HTMLElement.prototype.toggleClasses = (classList = []) => {
    for(let k = 0; k < classList.length; k++)
        this.classList.toggle(classList[k]);
}

function toggleModal(modalId) {
    console.log('Toggligng modal <'+modalId+'>')
    try {
        let modal = document.getElementById(modalId)
        if(window.getComputedStyle(modal).display === 'none') {
            Velocity(modal, 'slideDown',  {
                duration: 400,
                delay: 50,
                easing: 'easeInSine',
                complete: () => {
                    // Scroll to the top:
                    window.scrollTo(0,0);
                }
            });
            
            
        } else {
            Velocity(modal, 'slideUp', 
            {
                duration: 400,
                delay: 50,
                easing: 'easeOutSine',
                begin: () => {
                    // Scroll to the top:
                    //window.scrollTo(0,0);
                }
            });
        }
    
    } catch(error) {
        console.log('Failed to toggled modalId <'+modalId+'>: '+error);
    }
}



document.addEventListener('DOMContentLoaded', (event) => {
    
    // Set up modal togglers; 
    // NOTE: modal = modal div *id*
    // toggler = toggler element class name
    [
        {modal:'modal-privacy-policy', toggler:'toggle-privacy-policy-modal'},
        {modal:'modal-tos', toggler:'toggle-tos-modal'},
    ].forEach(m => {
        // Loop through all toggler classes & add onClick event:
        Array.from(document.getElementsByClassName(m.toggler)).forEach(toggler => {
            toggler.addEventListener('click', e => {
                e.preventDefault();
                //console.log('Added event listener to '+toggler);
                toggleModal(m.modal);
            });
        });
    });
    
});